import isFunction from 'lodash/isFunction';
import color from 'color';
import cookieHelper from '../../utils/cookieHelper';
import globalConst from '../../constants';

export const LOAD_PROFILE = 'redux-ducks/auth/LOAD_PROFILE';
export const LOAD_PROFILE_SUCCESS = 'redux-ducks/auth/LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_FAIL = 'redux-ducks/auth/LOAD_PROFILE_FAIL';

const LOGIN = 'redux-ducks/auth/LOGIN';
const LOGIN_SUCCESS = 'redux-ducks/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'redux-ducks/auth/LOGIN_FAIL';

const LOGIN_RECOVERY = 'redux-ducks/auth/LOGIN_RECOVERY';
const LOGIN_RECOVERY_SUCCESS = 'redux-ducks/auth/LOGIN_RECOVERY_SUCCESS';
const LOGIN_RECOVERY_FAIL = 'redux-ducks/auth/LOGIN_RECOVERY_FAIL';

const SIGNUP = 'redux-ducks/auth/SIGNUP';
const SIGNUP_SUCCESS = 'redux-ducks/auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'redux-ducks/auth/SIGNUP_FAIL';
const CLIENT_TOKEN = 'redux-ducks/auth/CLIENT_TOKEN';
const CLIENT_TOKEN_SUCCESS = 'redux-ducks/auth/CLIENT_TOKEN_SUCCESS';
const CLIENT_TOKEN_FAIL = 'redux-ducks/auth/CLIENT_TOKEN_FAIL';
const LOGOUT = 'redux-ducks/auth/LOGOUT';
const LOGOUT_SUCCESS = 'redux-ducks/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'redux-ducks/auth/LOGOUT_FAIL';
const SET_DEFAULT = 'redux-ducks/auth/SET_DEFAULT';

const GET_MARKETING_INFO = 'redux-ducks/auth/GET_MARKETING_INFO';
const GET_MARKETING_INFO_SUCCESS = 'redux-ducks/auth/GET_MARKETING_INFO_SUCCESS';
const GET_MARKETING_INFO_FAIL = 'redux-ducks/auth/GET_MARKETING_INFO_FAIL';

const GET_AGENCY_INFO = 'redux-ducks/auth/GET_AGENCY_INFO';
const GET_AGENCY_INFO_SUCCESS = 'redux-ducks/auth/GET_AGENCY_INFO_SUCCESS';
const GET_AGENCY_INFO_FAIL = 'redux-ducks/auth/GET_AGENCY_INFO_FAIL';

const UPDATE_AGENCY_INFO = 'redux-ducks/auth/UPDATE_AGENCY_INFO';
const UPDATE_AGENCY_INFO_SUCCESS = 'redux-ducks/auth/UPDATE_AGENCY_INFO_SUCCESS';
const UPDATE_AGENCY_INFO_FAIL = 'redux-ducks/auth/UPDATE_AGENCY_INFO_FAIL';

const UPDATE_BRAND_COLOR = 'redux-ducks/auth/UPDATE_BRAND_COLOR';

const UPDATE_PROFILE = 'redux-ducks/auth/UPDATE_PROFILE';
const UPDATE_PROFILE_SUCCESS = 'redux-ducks/auth/UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_FAIL = 'redux-ducks/auth/UPDATE_FAIL';

const UPLOAD = 'redux-ducks/auth/UPLOAD';
const UPLOAD_SUCCESS = 'redux-ducks/auth/UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'redux-ducks/auth/UPLOAD_FAIL';

const UPLOAD_LOGO = 'redux-ducks/auth/UPLOAD_LOGO';
const UPLOAD_LOGO_SUCCESS = 'redux-ducks/auth/UPLOAD_LOGO_SUCCESS';
const UPLOAD_LOGO_FAIL = 'redux-ducks/auth/UPLOAD_LOGO_FAIL';

const REMOVE_LOGO = 'redux-ducks/auth/REMOVE_LOGO';
const REMOVE_LOGO_SUCCESS = 'redux-ducks/auth/REMOVE_LOGO_SUCCESS';
const REMOVE_LOGO_FAIL = 'redux-ducks/auth/REMOVE_LOGO_FAIL';

const REMOVE_PHOTO = 'redux-ducks/auth/REMOVE_PHOTO';
const REMOVE_PHOTO_SUCCESS = 'redux-ducks/auth/REMOVE_PHOTO_SUCCESS';
const REMOVE_PHOTO_FAIL = 'redux-ducks/auth/REMOVE_PHOTO_FAIL';

const RESET_PWD = 'redux-ducks/auth/RESET_PWD';
const RESET_PWD_SUCCESS = 'redux-ducks/auth/RESET_PWD_SUCCESS';
const RESET_PWD_FAIL = 'redux-ducks/auth/RESET_PWD_FAIL';

const RESET_PWD_CONFIRM = 'redux-ducks/auth/RESET_PWD_CONFIRM';
const RESET_PWD_CONFIRM_SUCCESS = 'redux-ducks/auth/RESET_PWD_CONFIRM_SUCCESS';
const RESET_PWD_CONFIRM_FAIL = 'redux-ducks/auth/RESET_PWD_CONFIRM_FAIL';

const RESET_PWD_WORKER_CONFIRM = 'redux-ducks/auth/RESET_PWD_WORKER_CONFIRM';
const RESET_PWD__WORKER_CONFIRM_SUCCESS = 'redux-ducks/auth/RESET_PWD__WORKER_CONFIRM_SUCCESS';
const RESET_PWD_WORKER_CONFIRM_FAIL = 'redux-ducks/auth/RESET_PWD_WORKER_CONFIRM_FAIL';

const LOAD_INFO = 'redux-ducks/auth/LOAD_INFO';
const LOAD_INFO_SUCCESS = 'redux-ducks/auth/LOAD_INFO_SUCCESS';
const LOAD_INFO_FAIL = 'redux-ducks/auth/LOAD_INFO_FAIL';

const UPDATE_FEATURE_SETTINGS = 'redux-ducks/auth/UPDATE_FEATURE_SETTINGS';
const UPDATE_FEATURE_SETTINGS_SUCCESS = 'redux-ducks/auth/UPDATE_FEATURE_SETTINGS_SUCCESS';
const UPDATE_FEATURE_SETTINGS_FAIL = 'redux-ducks/auth/UPDATE_FEATURE_SETTINGS_FAIL';

const SEND_HS_FORM_SUCCESS = 'redux-ducks/auth/SEND_HS_FORM_SUCCESS';
const SEND_HS_FORM = 'redux-ducks/auth/SEND_HS_FORM';
const SEND_HS_FORM_FAIL = 'redux-ducks/auth/SEND_HS_FORM_FAIL';

const CREATE_HS_CONTACT = 'redux-ducks/auth/CREATE_HS_CONTACT';
const CREATE_HS_CONTACT_SUCCESS = 'redux-ducks/auth/CREATE_HS_CONTACT_SUCCESS';
const CREATE_HS_CONTACT_FAIL = 'redux-ducks/auth/CREATE_HS_CONTACT_FAIL';

const UPDATE_HS_CONTACT = 'redux-ducks/auth/UPDATE_HS_CONTACT';
const UPDATE_HS_CONTACT_SUCCESS = 'redux-ducks/auth/UPDATE_HS_CONTACT_SUCCESS';
const UPDATE_HS_CONTACT_FAIL = 'redux-ducks/auth/UPDATE_HS_CONTACT_FAIL';

const LOAD_BILLING_INFO = 'redux-duck/auth/LOAD_BILLING_INFO';
const LOAD_BILLING_INFO_SUCCESS = 'redux-duck/auth/LOAD_BILLING_INFO_SUCCESS';
const LOAD_BILLING_INFO_FAIL = 'redux-duck/auth/LOAD_BILLING_INFO_FAIL';

const UPDATE_BILLING_INFO = 'redux-duck/auth/UPDATE_BILLING_INFO';
const UPDATE_BILLING_INFO_SUCCESS = 'redux-duck/auth/UPDATE_BILLING_INFO_SUCCESS';
const UPDATE_BILLING_INFO_FAIL = 'redux-duck/auth/UPDATE_BILLING_INFO_FAIL';

const GET_AGENCY_FEES = 'redux-duck/auth/GET_AGENCY_FEES';
const GET_AGENCY_FEES_SUCCESS = 'redux-duck/auth/GET_AGENCY_FEES_SUCCESS';
const GET_AGENCY_FEES_FAIL = 'redux-duck/auth/GET_AGENCY_FEES_FAIL';

const SAVE_AGENCY_FEE = 'redux-duck/auth/SAVE_AGENCY_FEE';
const SAVE_AGENCY_FEE_SUCCESS = 'redux-duck/auth/SAVE_AGENCY_FEE_SUCCESS';
const SAVE_AGENCY_FEE_FAIL = 'redux-duck/auth/SAVE_AGENCY_FEE_FAIL';

const REMOVE_AGENCY_FEE = 'redux-duck/auth/REMOVE_AGENCY_FEE';
const REMOVE_AGENCY_FEE_SUCCESS = 'redux-duck/auth/REMOVE_AGENCY_FEE_SUCCESS';
const REMOVE_AGENCY_FEE_FAIL = 'redux-duck/auth/REMOVE_AGENCY_FEE__FAIL';

const UPDATE_AGENCY_FEE = 'redux-duck/auth/UPDATE_AGENCY_FEE';
const UPDATE_AGENCY_FEE_SUCCESS = 'redux-duck/auth/UPDATE_AGENCY_FEE_SUCCESS';
const UPDATE_AGENCY_FEE_FAIL = 'redux-duck/auth/UPDATE_AGENCY_FEE_FAIL';

const LOAD_TOKEN = 'redux-duck/auth/LOAD_TOKEN';
const LOAD_TOKEN_SUCCESS = 'redux-duck/auth/LOAD_TOKEN_SUCCESS';
const LOAD_TOKEN_FAIL = 'redux-duck/auth/LOAD_TOKEN_FAIL';

const CREATE_TOKEN = 'redux-duck/auth/CREATE_TOKEN';
const CREATE_TOKEN_SUCCESS = 'redux-duck/auth/CREATE_TOKEN_SUCCESS';
const CREATE_TOKEN_FAIL = 'redux-duck/auth/CREATE_TOKEN_FAIL';

const DELETE_TOKEN = 'redux-duck/auth/DELETE_TOKEN';
const DELETE_TOKEN_SUCCESS = 'redux-duck/auth/DELETE_TOKEN_SUCCESS';
const DELETE_TOKEN_FAIL = 'redux-duck/auth/DELETE_TOKEN_FAIL';

const LOAD_EVIDOS = 'redux-duck/auth/LOAD_EVIDOS';
const LOAD_EVIDOS_SUCCESS = 'redux-duck/auth/LOAD_EVIDOS_SUCCESS';
const LOAD_EVIDOS_FAIL = 'redux-duck/auth/LOAD_EVIDOS_FAIL';

const CREATE_EVIDOS = 'redux-duck/auth/CREATE_EVIDOS';
const CREATE_EVIDOS_SUCCESS = 'redux-duck/auth/CREATE_EVIDOS_SUCCESS';
const CREATE_EVIDOS_FAIL = 'redux-duck/auth/CREATE_EVIDOS_FAIL';

const DELETE_EVIDOS = 'redux-duck/auth/DELETE_EVIDOS';
const DELETE_EVIDOS_SUCCESS = 'redux-duck/auth/DELETE_EVIDOS_SUCCESS';
const DELETE_EVIDOS_FAIL = 'redux-duck/auth/DELETE_EVIDOS_FAIL';

const LOAD_EXACT = 'redux-duck/auth/LOAD_EXACT';
const LOAD_EXACT_SUCCESS = 'redux-duck/auth/LOAD_EXACT_SUCCESS';
const LOAD_EXACT_FAIL = 'redux-duck/auth/LOAD_EXACT_FAIL';

const UPDATE_EXACT = 'redux-duck/auth/UPDATE_EXACT';
const UPDATE_EXACT_SUCCESS = 'redux-duck/auth/UPDATE_EXACT_SUCCESS';
const UPDATE_EXACT_FAIL = 'redux-duck/auth/UPDATE_EXACT_FAIL';

const LOAD_INTUS = 'redux-duck/auth/LOAD_INTUS';
const LOAD_INTUS_SUCCESS = 'redux-duck/auth/LOAD_INTUS_SUCCESS';
const LOAD_INTUS_FAIL = 'redux-duck/auth/LOAD_INTUS_FAIL';

const CREATE_INTUS = 'redux-duck/auth/CREATE_INTUS';
const CREATE_INTUS_SUCCESS = 'redux-duck/auth/CREATE_INTUS_SUCCESS';
const CREATE_INTUS_FAIL = 'redux-duck/auth/CREATE_INTUS_FAIL';

const UPDATE_INTUS = 'redux-duck/auth/UPDATE_INTUS';
const UPDATE_INTUS_SUCCESS = 'redux-duck/auth/UPDATE_INTUS_SUCCESS';
const UPDATE_INTUS_FAIL = 'redux-duck/auth/UPDATE_INTUS_FAIL';

const DELETE_INTUS = 'redux-duck/auth/DELETE_INTUS';
const DELETE_INTUS_SUCCESS = 'redux-duck/auth/DELETE_INTUS_SUCCESS';
const DELETE_INTUS_FAIL = 'redux-duck/auth/DELETE_INTUS_FAIL';

const LOAD_2F_AUTH = 'redux-duck/auth/LOAD_2F_AUTH';
const LOAD_2F_AUTH_SUCCESS = 'redux-duck/auth/LOAD_2F_AUTH_SUCCESS';
const LOAD_2F_AUTH_FAIL = 'redux-duck/auth/LOAD_2F_AUTH_FAIL';

const CREATE_2F_AUTH = 'redux-duck/auth/CREATE_2F_AUTH';
const CREATE_2F_AUTH_SUCCESS = 'redux-duck/auth/CREATE_2F_AUTH_SUCCESS';
const CREATE_2F_AUTH_FAIL = 'redux-duck/auth/CREATE_2F_AUTH_FAIL';

const REMOVE_2F_AUTH = 'redux-duck/auth/REMOVE_2F_AUTH';
const REMOVE_2F_AUTH_SUCCESS = 'redux-duck/auth/REMOVE_2F_AUTH_SUCCESS';
const REMOVE_2F_AUTH_FAIL = 'redux-duck/auth/REMOVE_2F_AUTH_FAIL';

const initialState = {
  userType: 'guest',
  current: {
    email: '',
    phone: '',
    brand_color: '',
    text_color: '#ffffff',
    feature_setting: {
      'is_advanced_filtering?': false,
      'is_agency_fee?': false,
      'is_branding?': false,
      'is_custom_fields?': false,
      'is_extended_report?': false,
      'is_external_managers?': false,
      'is_importing_shifts?': false,
      'is_internal_managers?': false,
      'is_manual_approve_shift?': false,
      'is_messaging?': false,
      'is_multiple_roles?': false,
      'is_ort?': false,
      'is_recurring_shifts?': false,
      'is_time_tracking?': false,
      'is_time_tracking_signature?': false,
      'is_distance_calculated?': false,
      'is_gig_category_settings?': false,
      'is_expenses?': false,
      'is_summary_invoice?': false,
      'is_fee_invoice?': false,
      'is_org_attributes_gig_filter?': false,
      'is_protected_workers_documents?': false,
      'is_agreements_generation?': false,
      'is_signing_documents?': false,
      distance_format: 'km_round_2',
      allowed_agency_fees: 5,
      allowed_unavailabilities: 5,
    },
    manager_feature_setting: {},
  },
  userInfo: {
    loaded: false,
    loading: false,
    country_code: 'NL',
    time_zone: 'Europe/Amsterdam',
  },
  agencyInfo: {
    title: '',
    brand_color: '',
    email: '',
    description: '',
    phone: '',
    photo: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    website: '',
    email_template: '',
  },
  billingInfo: {},
  agencyFees: [],
  externalTokens: [],
  evidosToken: {},
  isMaintenance: false,
};

function removeAllCookie() {
  cookieHelper.remove('type');
  cookieHelper.remove('token');
  cookieHelper.remove('org');
  cookieHelper.remove('trial-popup');
  cookieHelper.remove('hs-updated');
  cookieHelper.remove('user_id');
  // cookieHelper.remove('table');
  // cookieHelper.remove('tableAttr');
}

function logoutRequest() {
  removeAllCookie();

  if (!cookieHelper.get('token')) {
    return Promise.resolve(true);
  }

  const err = { error: 'Enable delete Token' };
  throw err;
}

/**
 * Load profile
 */
const loadProfile = () => {
  const include = 'include=feature_setting,notification_setting,manager_feature_setting';
  return ({
    types: [LOAD_PROFILE, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAIL],
    promise: (client) => client.get(`v3/planners/profile?${include}`),
  });
};

/**
 * Update profile
 * @param {object} data
 */
export const updateProfile = (data) => {
  const include = 'include=feature_setting,notification_setting,manager_feature_setting';
  return ({
    types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL],
    promise: (client) => client.patch(`v3/planners/profile?${include}`, { data })
  });
};

export const setDefault = () => ({
  type: SET_DEFAULT
});

/**
 * Signup confirmation
 * @param {string} token confirmation_token
 */
export const signupSuccess = (token) => ({
  types: [CLIENT_TOKEN, CLIENT_TOKEN_SUCCESS, CLIENT_TOKEN_FAIL],
  promise: (client) => client.get(`clients/confirmation?confirmation_token=${token}`)
});

export function resetPWD(email) {
  return {
    types: [RESET_PWD, RESET_PWD_SUCCESS, RESET_PWD_FAIL],
    promise: (client) => client.get(`password_reset?email=${email}&guest`),
  };
}

/**
 * Handle upload photo
 * @param {object} data
 */
export function uploadPhoto(data) {
  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    promise: (client) => client.patch('photo', { data })
  };
}

export function removePhoto() {
  return {
    types: [REMOVE_PHOTO, REMOVE_PHOTO_SUCCESS, REMOVE_PHOTO_FAIL],
    promise: (client) => client.del('photo')
  };
}

export function resetPWDConfirm(data) {
  return {
    types: [RESET_PWD_CONFIRM, RESET_PWD_CONFIRM_SUCCESS, RESET_PWD_CONFIRM_FAIL],
    promise: (client) => client.post('password_reset?&guest', { data })
  };
}

export function resetPWDWorkersConfirm(data) {
  return {
    types: [RESET_PWD_WORKER_CONFIRM, RESET_PWD__WORKER_CONFIRM_SUCCESS, RESET_PWD_WORKER_CONFIRM_FAIL],
    promise: (client) => client.post('password_reset?&guest', { data })
  };
}

/**
 * Login action
 * @param {object} data
 */
export const login = (data) => ({
  types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
  promise: (client) => client.post('v3/session', { data })
});

export const loginRecoveryCode = (data) => ({
  types: [LOGIN_RECOVERY, LOGIN_RECOVERY_SUCCESS, LOGIN_RECOVERY_FAIL],
  promise: (client) => client.post('v3/recovery', { data })
})

export const loginManagerRequest = (data) => ({
  types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
  promise: (client) => client.post('v3/planners/token_session', { data })
});

/**
 * Signup
 * @param {object} data
 */
export const signup = (data) => ({
  types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
  promise: (client) => client.post('v3/clients', { data })
});

/**
 * Logout
 */
export const logout = () => ({
  types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
  promise: () => logoutRequest()
});

/**
 * Feature settings v3
 */
export const updateFeatureSettings = (data) => ({
  types: [UPDATE_FEATURE_SETTINGS, UPDATE_FEATURE_SETTINGS_SUCCESS, UPDATE_FEATURE_SETTINGS_FAIL],
  promise: (client) => client.patch('/v3/planners/feature_setting', { data })
});

/**
 * Get agency info
 */
export const fetchAgencyInfo = () => ({
  types: [GET_AGENCY_INFO, GET_AGENCY_INFO_SUCCESS, GET_AGENCY_INFO_FAIL],
  promise: (client) => client.get('v3/planners/agency_information')
});

export const updateAgencyInfo = (data) => ({
  types: [UPDATE_AGENCY_INFO, UPDATE_AGENCY_INFO_SUCCESS, UPDATE_AGENCY_INFO_FAIL],
  promise: (client) => client.patch('v3/planners/agency_information', { data })
});

export const getMarketingInfo = () => ({
  types: [GET_MARKETING_INFO, GET_MARKETING_INFO_SUCCESS, GET_MARKETING_INFO_FAIL],
  promise: (client) => client.get('marketing_info')
});

export const loadBillingInfo = () => ({
  types: [LOAD_BILLING_INFO, LOAD_BILLING_INFO_SUCCESS, LOAD_BILLING_INFO_FAIL],
  promise: (client) => client.get('v3/planners/agency_information/billing_info'),
});

export const updateBillingInfo = (data) => ({
  types: [UPDATE_BILLING_INFO, UPDATE_BILLING_INFO_SUCCESS, UPDATE_BILLING_INFO_FAIL],
  promise: (client) => client.patch('v3/planners/agency_information/billing_info', { data }),
});

export const loadAgencyFees = () => ({
  types: [GET_AGENCY_FEES, GET_AGENCY_FEES_SUCCESS, GET_AGENCY_FEES_FAIL],
  promise: (client) => client.get('v3/planners/agency_fees')
});

export const saveAgencyFee = (data) => ({
  types: [SAVE_AGENCY_FEE, SAVE_AGENCY_FEE_SUCCESS, SAVE_AGENCY_FEE_FAIL],
  promise: (client) => client.post('v3/planners/agency_fees', { data })
});

export const removeAgencyFee = (id) => ({
  types: [REMOVE_AGENCY_FEE, REMOVE_AGENCY_FEE_SUCCESS, REMOVE_AGENCY_FEE_FAIL],
  promise: (client) => client.del(`v3/planners/agency_fees/${id}`),
  id
});

export const updateAgencyFee = (data) => ({
  types: [UPDATE_AGENCY_FEE, UPDATE_AGENCY_FEE_SUCCESS, UPDATE_AGENCY_FEE_FAIL],
  promise: (client) => client.patch(`v3/planners/agency_fees/${data.agency_fee.id}`, { data })
});
/**
 * Handle upload billing logo
 * @param {object} data
 */
export function uploadBillingLogo(data) {
  return {
    types: [UPLOAD_LOGO, UPLOAD_LOGO_SUCCESS, UPLOAD_LOGO_FAIL],
    promise: (client) => client.patch('v3/planners/agency_information/billing_info/logo', { data })
  };
}

/**
 * Handle remove billing logo
 */
export function removeBillingLogo() {
  return {
    types: [REMOVE_LOGO, REMOVE_LOGO_SUCCESS, REMOVE_LOGO_FAIL],
    promise: (client) => client.del('v3/planners/agency_information/billing_info/logo')
  };
}

/**
 * Get external token
 */
export function loadExternalToken() {
  return {
    types: [LOAD_TOKEN, LOAD_TOKEN_SUCCESS, LOAD_TOKEN_FAIL],
    promise: (client) => client.get('v3/planners/external_integrations')
  };
}

/**
 * Create external token
 */
export function createExternalToken(data) {
  return {
    types: [CREATE_TOKEN, CREATE_TOKEN_SUCCESS, CREATE_TOKEN_FAIL],
    promise: (client) => client.post('v3/planners/external_integrations', { data })
  };
}

/**
 * Delete external token
 */
export function deleteExternalToken(id) {
  return {
    types: [DELETE_TOKEN, DELETE_TOKEN_SUCCESS, DELETE_TOKEN_FAIL],
    promise: (client) => client.del(`v3/planners/external_integrations/${id}`),
    id
  };
}

/**
 * Get external token
 */
export function loadEvidosToken() {
  return {
    types: [LOAD_EVIDOS, LOAD_EVIDOS_SUCCESS, LOAD_EVIDOS_FAIL],
    promise: (client) => client.get('v3/planners/external_integrations/evidos')
  };
}

/**
 * Create external EVIDOS
 */
export function createEvidosToken(data) {
  return {
    types: [CREATE_EVIDOS, CREATE_EVIDOS_SUCCESS, CREATE_EVIDOS_FAIL],
    promise: (client) => client.post('v3/planners/external_integrations/evidos', { data })
  };
}

/**
 * Delete external EVIDOS
 */
export function deleteEvidosToken() {
  return {
    types: [DELETE_EVIDOS, DELETE_EVIDOS_SUCCESS, DELETE_EVIDOS_FAIL],
    promise: (client) => client.del('v3/planners/external_integrations/evidos'),
  };
}

/**
 * Load exact api params
 */
export const loadExactApi = () => ({
  types: [LOAD_EXACT, LOAD_EXACT_SUCCESS, LOAD_EXACT_FAIL],
  promise: (client) => client.get('v3/planners/exact/settings?include=exact_expense_accounts')
});

/**
 * Update exact api params
 */
export const updateExactApi = (data) => ({
  types: [UPDATE_EXACT, UPDATE_EXACT_SUCCESS, UPDATE_EXACT_FAIL],
  promise: (client) => client.patch('v3/planners/exact/settings?include=exact_expense_accounts', { data })
});

/*
 * Get INTUS token
 */
export const loadIntusToken = () => ({
  types: [LOAD_INTUS, LOAD_INTUS_SUCCESS, LOAD_INTUS_FAIL],
  promise: (client) => client.get('v3/planners/external_integrations/intus')
});

/**
 * Create external INTUS
 */
export const createIntusToken = (data) => ({
  types: [CREATE_INTUS, CREATE_INTUS_SUCCESS, CREATE_INTUS_FAIL],
  promise: (client) => client.post('v3/planners/external_integrations/intus', { data })
});

/**
 * Update external INTUS
 */
export const updateIntusToken = (data) => ({
  types: [UPDATE_INTUS, UPDATE_INTUS_SUCCESS, UPDATE_INTUS_FAIL],
  promise: (client) => client.patch(`v3/planners/external_integrations/intus/${data.id}`, { data })
});

/**
 * Delete external INTUS
 */
export const deleteIntusToken = (id) => ({
  types: [DELETE_INTUS, DELETE_INTUS_SUCCESS, DELETE_INTUS_FAIL],
  promise: (client) => client.del(`v3/planners/external_integrations/intus/${id}`),
});

/**
 * Two factor authorization
 */
export const loadTwoFactorAuth = () => ({
  types: [LOAD_2F_AUTH, LOAD_2F_AUTH_SUCCESS, LOAD_2F_AUTH_FAIL],
  promise: (client) => client.get('v3/planners/two_factor_auth_setting/new'),
});
export const createTwoFactorAuth = (data) => ({
  types: [CREATE_2F_AUTH, CREATE_2F_AUTH_SUCCESS, CREATE_2F_AUTH_FAIL],
  promise: (client) => client.post('v3/planners/two_factor_auth_setting', { data }),
});
export const removeTwoFactorAuth = () => ({
  types: [REMOVE_2F_AUTH, REMOVE_2F_AUTH_SUCCESS, REMOVE_2F_AUTH_FAIL],
  promise: (client) => client.del(`v3/planners/two_factor_auth_setting`),
});

export function loadInfoBegin() {
  return {
    type: LOAD_INFO,
  };
}

export function loadInfoResult(data) {
  return {
    type: LOAD_INFO_SUCCESS,
    result: data,
  };
}

export function loadInfoError() {
  return {
    type: LOAD_INFO_FAIL,
  };
}

/**
 * Update brand color
 * @param {string} brandColor
 */
export const updateBrandColor = (brandColor) => ({
  type: UPDATE_BRAND_COLOR,
  result: brandColor,
});

/**
 * Set default country NL
 */
export const loadInfo = () => async (dispatch) => {
  const params = { country_code: 'NL' };

  await dispatch(loadInfoResult(params));

  return Promise.resolve(params);
};

// export function loadInfo() {
//   return (dispatch) => {
//     dispatch(loadInfoBegin());

//     return httpGet('https://freegeoip.net/json/?callback=')
//       .then((resp) => {
//         dispatch(loadInfoResult(resp.body));
//       }).catch(() => {
//         dispatch(loadInfoError());
//       });
//   };
// }

/**
 * Send data to hubspot
 * @param {object} data
 */
const sendForm = (data) => ({
  types: [SEND_HS_FORM, SEND_HS_FORM_SUCCESS, SEND_HS_FORM_FAIL],
  promise: (client) => client.post('/fapi/hubSpotForm', { data }),
});

/**
 * Create hubSpot contact
 * @param {object} data
 */
const createHSContact = (data) => ({
  types: [CREATE_HS_CONTACT, CREATE_HS_CONTACT_SUCCESS, CREATE_HS_CONTACT_FAIL],
  promise: (client) => client.post('/fapi/hubSpotCreateContact', { data }),
});

/**
 * Update hubSpot contact
 * @param {object} data
 */
const updateHSContact = (data) => ({
  types: [UPDATE_HS_CONTACT, UPDATE_HS_CONTACT_SUCCESS, UPDATE_HS_CONTACT_FAIL],
  promise: (client) => client.post('/fapi/hubSpotUpdateContact', { data }),
});

/**
 * Send params to HubSpot form
 */
export const sendToHubSpot = (data) => (dispatch) => {
  delete data.password;

  return dispatch(sendForm(data));
};
export const createHubSpotContact = (params) => (dispatch) => dispatch(createHSContact(params));
export const updateHubSpotContact = (params) => (dispatch) => dispatch(updateHSContact(params));

export const loadAuth = () => (dispatch, getState) => {
  if (getState().auth.isMaintenance) {
    return Promise.resolve('Error: maintenance in progress, try again later');
  }

  if (cookieHelper.get('token')) {
    return dispatch(loadProfile())
      .catch(() => console.log('Error: load profile')); //eslint-disable-line
  }

  return dispatch(setDefault());
};

/**
 * MUTATIONS
 */
const updateProfileSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const profile = data.attributes;
  const brandColor = data.attributes.brand_color || '';
  const textColor = brandColor && color(brandColor).isLight() ? '#262626' : '#ffffff';
  const userType = data.attributes.user_type;

  const featureSetting = included.find((item) => item.type === 'planner_feature_setting') || {};
  const notificationSetting = included.find((item) => item.type === 'notification_setting') || {};
  const managerFeatureSetting = included.find((item) => item.type === 'manager_feature_setting') || {};

  cookieHelper.save('user_id', profile.id);

  return ({
    ...state,
    current: {
      ...state.current,
      ...profile,
      brand_color: brandColor,
      text_color: textColor,
      feature_setting: {
        ...state.current.feature_setting,
        ...featureSetting.attributes,
      },
      notification_setting: {
        ...state.current.notification_setting,
        ...notificationSetting.attributes,
      },
      manager_feature_setting: {
        ...state.current.manager_feature_setting,
        ...managerFeatureSetting.attributes,
      },
    },
    userType,
  });
};

const loginSuccess = (action) => (state) => {
  const { data } = action.result;

  const userType = data?.attributes.user_type;
  if (!userType || userType !== globalConst.CLIENTS_STR && userType !== globalConst.MANAGERS_STR) {
    return ({
      ...state,
    });
  }

  const userToken = data?.attributes.token || '';
  const brandColor = data?.attributes.brand_color || '';
  const textColor = brandColor && color(brandColor).isLight() ? '#262626' : '#ffffff';

  cookieHelper.save('token', userToken);
  cookieHelper.save('type', userType);

  return ({
    ...state,
    userType,
    current: {
      ...state.current,
      brand_color: brandColor,
      text_color: textColor,
    },
  });
};

const signupSuccessRequest = (action) => (state) => {
  const { data } = action.result;

  const userType = data.attributes.user_type;
  const userToken = data.attributes.token;

  cookieHelper.save('token', userToken);
  cookieHelper.save('type', userType);

  return ({
    ...state,
    userType,
  });
};

const uploadSuccess = (action) => (state) => {
  const clients = action.result.client;
  const current = clients || action.result.manager;

  return ({
    ...state,
    current: {
      ...state.current,
      ...current,
    },
  });
};

const removePhotoSuccess = () => (state) => ({
  ...state,
  current: {
    ...state.current,
    photo: ''
  }
});

const loadInfoStart = () => (state) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    loading: true,
  }
});

const loadInfoSuccess = (action) => (state) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    loaded: true,
    loading: false,
    ...action.result,
  }
});

const loadInfoFail = () => (state) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    loaded: true,
    loading: false,
  }
});

const getAgencyInfoSuccess = (action) => (state) => {
  const { data } = action.result;
  const brandColor = data?.attributes.brand_color || '';
  const textColor = brandColor && color(brandColor).isLight() ? '#262626' : '#ffffff';

  return ({
    ...state,
    current: {
      ...state.current,
      brand_color: brandColor,
      text_color: textColor,
    },
    agencyInfo: {
      title: data?.attributes.title || '',
      brand_color: brandColor,
      email: data?.attributes.email || '',
      description: data?.attributes.description || '',
      photo: data?.attributes.photo || '',
      phone: data?.attributes.phone || '',
      facebook: data?.attributes.facebook || '',
      instagram: data?.attributes.instagram || '',
      linkedin: data?.attributes.linkedin || '',
      website: data?.attributes.website || '',
      email_template: data?.attributes.email_template || '',
    }
  });
};

const updateBrandColorSuccess = (action) => (state) => ({
  ...state,
  current: {
    ...state.current,
    brand_color: action.result,
    text_color: action.result && color(action.result).isLight() ? '#262626' : '#ffffff',
  },
});

const updateFeatureSettingsSuccess = (action) => (state) => ({
  ...state,
  current: {
    ...state.current,
    feature_setting: {
      ...state.current.feature_setting,
      ...action.result.data.attributes,
    },
  }
});

const updateBillingInfoSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      companyName: data.attributes.company_name || '',
      address: data.attributes.address || '',
      code: data.attributes.postal_code || '',
      city: data.attributes.city || '',
      KVKNumber: data.attributes.kvk || '',
      btwNumber: data.attributes.vat || '',
      IBANNumber: data.attributes.iban || '',
      buyingCCEmail: data.attributes.buying_cc_email || '',
      buyingCCEmailAttachments: data.attributes.buying_cc_email_attachments || 'pdf',
      sellingCCEmail: data.attributes.selling_cc_email || '',
      sellingCCEmailAttachments: data.attributes.selling_cc_email_attachments || 'pdf',
      phone: data.attributes.phone || '',
      invoiceTo: data.attributes.to_field || '',
      paymentTermInfo: data.attributes.payment_term_info || '',
      logo: data.attributes.logo || '',
      correctness: data.attributes.correctness,
    }
  });
};

const getAgencyFeesSuccess = (action) => (state) => {
  const { data } = action.result;
  const agencyFees = data.map((el) => el.attributes);

  return ({
    ...state,
    agencyFees: [...agencyFees]
  });
};

const saveAgencyFeesSuccess = (action) => (state) => {
  const { data } = action.result;
  return ({
    ...state,
    agencyFees: [...state.agencyFees, data.attributes]
  });
};

const removeAgencyFeeSuccess = (action) => (state) => {
  const filteredFees = state.agencyFees.filter((el) => el.id !== action.id);
  return ({
    ...state,
    agencyFees: [...filteredFees]
  });
};

const updateAgencyFeesSuccess = (action) => (state) => {
  const { data } = action.result;
  const updatedFees = state.agencyFees.map((el) => {
    if (el.id === data.attributes.id) {
      return { ...el, ...data.attributes };
    }
    return el;
  });

  return ({
    ...state,
    agencyFees: [...updatedFees]
  });
};

const setDefaultSuccess = () => {
  removeAllCookie();
  return {
    ...initialState,
  };
};

const loadTokenSuccess = (action) => (state) => {
  const { data } = action.result;

  const tokens = data.map((el) => el.attributes);

  return ({
    ...state,
    externalTokens: tokens
  });
};

const createTokenSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    externalTokens: [...state.externalTokens, data.attributes],
  });
};
const removeTokenSuccess = (action) => (state) => {
  const { id } = action;
  const filteredTokens = state.externalTokens.filter((token) => token.id !== id);
  return ({
    ...state,
    externalTokens: filteredTokens,
  });
};

// 1. Load Evidos Token Success
const loadEvidosTokenSuccess = (action) => (state) => {
  const { data } = action.result;
  return ({
    ...state,
    evidosToken: data.attributes,
  });
};

// 2. Create Evidos Token Success
const createEvidosTokenSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    evidosToken: data.attributes,
  });
};

// 3. Delete Evidos Token Success
const deleteEvidosTokenSuccess = () => (state) => ({
  ...state,
  evidosToken: ''
});

const actionsLookup = {
  [LOGIN_SUCCESS]: (state, action) => loginSuccess(action)(state),
  [LOGIN_RECOVERY_SUCCESS]: (state, action) => loginSuccess(action)(state),
  [RESET_PWD_CONFIRM_SUCCESS]: (state, action) => loginSuccess(action, false)(state),
  [LOAD_PROFILE_SUCCESS]: (state, action) => updateProfileSuccess(action)(state),
  [LOAD_PROFILE_FAIL]: () => setDefaultSuccess(),
  [UPDATE_PROFILE_SUCCESS]: (state, action) => updateProfileSuccess(action)(state),
  [SIGNUP_SUCCESS]: (state, action) => signupSuccessRequest(action)(state),
  [LOGOUT_SUCCESS]: () => setDefaultSuccess(),
  [UPLOAD_SUCCESS]: (state, action) => uploadSuccess(action)(state),
  [REMOVE_PHOTO_SUCCESS]: (state) => removePhotoSuccess()(state),
  [LOAD_INFO]: (state) => loadInfoStart()(state),
  [LOAD_INFO_SUCCESS]: (state, action) => loadInfoSuccess(action)(state),
  [LOAD_INFO_FAIL]: (state) => loadInfoFail()(state),
  [GET_AGENCY_INFO_SUCCESS]: (state, action) => getAgencyInfoSuccess(action)(state),
  [UPDATE_AGENCY_INFO_SUCCESS]: (state, action) => getAgencyInfoSuccess(action)(state),
  [UPDATE_BRAND_COLOR]: (state, action) => updateBrandColorSuccess(action)(state),
  [UPDATE_FEATURE_SETTINGS_SUCCESS]: (state, action) => updateFeatureSettingsSuccess(action)(state),
  [LOAD_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [UPDATE_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [LOAD_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [GET_AGENCY_FEES_SUCCESS]: (state, action) => getAgencyFeesSuccess(action)(state),
  [SAVE_AGENCY_FEE_SUCCESS]: (state, action) => saveAgencyFeesSuccess(action)(state),
  [REMOVE_AGENCY_FEE_SUCCESS]: (state, action) => removeAgencyFeeSuccess(action)(state),
  [UPDATE_AGENCY_FEE_SUCCESS]: (state, action) => updateAgencyFeesSuccess(action)(state),
  [LOAD_TOKEN_SUCCESS]: (state, action) => loadTokenSuccess(action)(state),
  [CREATE_TOKEN_SUCCESS]: (state, action) => createTokenSuccess(action)(state),
  [DELETE_TOKEN_SUCCESS]: (state, action) => removeTokenSuccess(action)(state),
  [LOAD_EVIDOS_SUCCESS]: (state, action) => loadEvidosTokenSuccess(action)(state),
  [CREATE_EVIDOS_SUCCESS]: (state, action) => createEvidosTokenSuccess(action)(state),
  [DELETE_EVIDOS_SUCCESS]: (state) => deleteEvidosTokenSuccess()(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
